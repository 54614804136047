const ApiNames = {
  generateToken: "/api/generateTokenWeb",
  authenticateToken: "/api/authenticateToken",
  getFLow: "/api/getFlow",
  awarenessFlow: "/api/awarenessFlow",
  sendOtp: "/api/sendWebOTP",
  verifyOtp: "/api/verifyWebOTP",
  loginSendOtp: "/api/sendLoginOTPWeb",
  loginVerifyOtp: "/api/verifyLoginOTPWeb",
  subscribeFastFlow: "/api/v2/ffSubscribeWeb",
  subscribe: "/api/v2/subscribeWeb",
  chekUser: "/api/subscriber/checkUser",
  checkOperator: "/api/subscriber/checkOp",
  categories: "/api/categories",
  courseByCat: "/api/collarCourse",
  collar: "/api/mostViewedCollar",
  getLevels: "/api/levels",
  searchData: "/api/search/data",
  deletBookMarks: "/api/collar/deletebookmark",
  courses: "/api/courses/",
  collarVideo: "/api/collarVideo",
  addView: "/api/v2/add/views",
  tipsAll: "/api/tipsAndTricks",
  removeCourseBookMarks: "/api/bookmarks/delete",
  addBookMark: "/api/bookmarks/addbookmark",
  addColorBookMark: "/api/collar/addbookmark",
  colarVideo: "/api/collarVideo",
  user: "/api/user",
  uploadImage: "/api/subscriber/uploadimage",
  userName: "/api/subscriber/name",
  userGrade: "/api/subscriber/update",
  quizTopics: "/api/questions",
  bookMarks: "/api/unifiedBookmarks",
  unsubscrib: "/api/subscriber/unsubscribeWeb",
  headerEnrichment: "/api/enrichment",
  utms: "/api/utmList",
  quranClass: "/api/quranClass",
  joinQuranClass: "/api/quranClass/join",
  Satcourses: "/api/ot/courses?subscriberId=",
  chapters: "/api/ot/chapters?moduleId=",
  topics: "/api/ot/topics?subscriberId=",
  bookMark: "/api/ot/bookmarkTopic",
  BookMark: "/api/ot/bookmarkedTopics?subscriberId=",
  deletebookmark: "/api/ot/deleteBookmarkTopic",
  unlockNextTopic: "/api/ot/unlockAndGetNextTopic",
  enrichment: "/api/enrichment",
};

export default ApiNames;
