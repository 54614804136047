import React, { useEffect, useState } from "react";
import ApiNames from "../constants/ApiNames";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";
import { fetchPost, fetchGet } from "../utils/FetchApi";
import Landing from "../components/Landing";
import { encode as base64_encode } from "base-64";
const LandingScreen = (props) => {
  const search = useLocation().search;

  const [phNumber, setphNumber] = useState("");
  const [error, setError] = useState({ Error: "", isTrue: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const [heOtpFlag, setHeOtpFlag] = useState(false);

  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  const parameters = searchParams.get("parameter")
    ? searchParams.get("parameter")
    : "";
  const queryParams = new URLSearchParams(search);
  const perameter = getParameterCaseInsensitive("p");

  const [miniLoading, setMiniLoading] = useState("false");
  const [userPackage, setUserPackage] = useState("");
  const [isModel, setIsModel] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);
  const initializeTagManager = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5TH9GVH",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const getHe = async () => {
    try {
      var endpoint = `${ApiNames.enrichment}`;

      let data = await fetchGet(endpoint, props.token);
      if (data.status === 200) {
        headerEnrichment(data);
      } else {
        if (props?.isLanding) {
          initializeTagManager();
        }
        console.log("SomeThing went wrong");
      }
    } catch (error) {
      if (props?.isLanding) {
        initializeTagManager();
      }
      console.warn("get flow error subscription", error);
    }
  };

  const headerEnrichment = (data) => {
    try {
      if (props?.isLanding) {
        initializeTagManager();
      }
      if (data.response.msisdn === "") {
        tagManagerEvents("Wifi_User", utm_source);
      } else {
        tagManagerEvents("Data_User", utm_source);
        setphNumber(data.response.msisdn.slice(2));
        setHeOtpFlag(true);
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };
  const onChangeMobileNumber = (value) => {
    setError((prevState) => ({
      ...prevState,
      ["Error"]: "",
      ["isTrue"]: false,
    }));
    if (value === "0" || !value.startsWith("3")) {
      setphNumber("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setphNumber(value);
      }
    }
  };

  const onClickProceed = () => {
    if (userPackage) {
      checkUserSubscription(userPackage);
    } else {
      checkOperator();
    }
  };

  const checkOperator = async () => {
    try {
      tagManagerEvents("Msisdn_Page", utm_source);
      setMiniLoading(true);
      let endpoint = `${ApiNames.checkOperator}?msisdn=${phNumber}`;
      let response = await fetchGet(endpoint, props.token);
      if (response.status === 200) {
        if (response.response.operator.toLowerCase() === "other") {
          setError((prevState) => ({
            ...prevState,
            ["Error"]:
              "Service is only available for Jazz customers, kindly enter a working Jazz number",
            ["isTrue"]: true,
          }));
          setMiniLoading(false);
        } else {
          checkUserSubscription(response.response.package);
        }
      }
    } catch (error) {
      setMiniLoading(false);
      console.log("Proceed Error ::", error);
    }
  };

  const checkUserSubscription = async (userPackage) => {
    try {
      let response = await fetchGet(
        `${ApiNames.chekUser}?msisdn=${phNumber}`,
        props.token
      );
      if (response.status === 200) {
        if (
          response.status === 200 &&
          response.response.desc.toLowerCase() === "active"
        ) {
          sendWebOtp(phNumber, userPackage, true);
        } else {
          sendWebOtp(phNumber, userPackage, false);
        }
      } else {
        sendWebOtp(phNumber, userPackage, false);
      }
    } catch (error) {
      sendWebOtp(phNumber, userPackage, false);
    }
  };

  const sendWebOtp = async (phNumber, userPackage, isUserActive) => {
    try {
      setMiniLoading(true);
      let endpoint = isUserActive ? ApiNames.loginSendOtp : ApiNames.sendOtp;
      let response = await fetchGet(
        `${endpoint}?msisdn=${phNumber}`,
        props.token
      );
      if (response.status === 200 && response.response.result === 0) {
        setMiniLoading(false);
        tagManagerEvents("Request_OTP", utm_source);

        navigate("/pin", {
          state: {
            msisdn: phNumber,
            utm_source: utm_source,
            parameters: parameters,
            userPackage: userPackage,
            isUserActive: isUserActive,
            HeFlag: heOtpFlag ? response.response.desc : null,
            uuid: response.response.uuid,
          },
        });
      } else {
        setError((prevState) => ({
          ...prevState,
          ["Error"]: "Something went wrong, Please try again later",
          ["isTrue"]: true,
        }));
        setMiniLoading(false);
      }
    } catch (error) {
      setError((prevState) => ({
        ...prevState,
        ["Error"]: "Something went wrong, Please try again later",
        ["isTrue"]: true,
      }));
      setMiniLoading(false);
    }
  };

  function getParameterCaseInsensitive(name) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null; // Return null if parameter is not found
  }

  const onHandleJoinNow = () => {
    setIsModel(false);
  };

  return (
    <Landing
      mobileNumber={phNumber}
      onChangeMobileNumber={onChangeMobileNumber}
      onClickProceed={onClickProceed}
      miniLoading={miniLoading}
      error={error}
      parameters={parameters}
      // isModel={isModel}
      isModel={false}
      onHandleJoinNow={onHandleJoinNow}
    />
  );
};

export default LandingScreen;
