import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ApiNames from "../constants/ApiNames";
import { encode as base64_encode } from "base-64";
import { fetchGet } from "../utils/FetchApi";
import Awareness from "../components/Awareness";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";

const AwarenessScreen = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  useEffect(() => {
    if (props.token) {
      getFlow();
    }
  }, [props.token]);
  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5TH9GVH",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const getFlow = async () => {
    try {
      var endpoint = `${ApiNames.awarenessFlow}`;
      endpoint = endpoint + "?utm_source=" + utm_source;
      let response = await fetchGet(endpoint, props.token);

      if (
        response.status === 208 &&
        response.response.desc.toLowerCase() !==
          "Subscriber Subscribed successfully"
      ) {
        tagManagerEvents("subscribe", "econTikTok");
        navigateToHome(response.response.msisdn, response.response.subscribeId);
      } else if (
        response.status === 208 &&
        response.response.message.toLowerCase() === "already subscribed"
      ) {
        navigateToHome(response.response.msisdn, response.response.subscribeId);
      } else {
        navigateToLanding();
      }
    } catch (error) {
      navigateToLanding();
    }
  };
  const navigateToLanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };

  const navigateToHome = (msisdn, subscribeId) => {
    let url = window.location.origin;
    let encodedMsisdn = base64_encode(msisdn);
    let encodedSubId = base64_encode(subscribeId);

    url = `${url}/home?ndsism=${encodedMsisdn}&subid=${encodedSubId}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return <Awareness />;
};

export default AwarenessScreen;
